export default {
    html: `
    <div class="right-cell">
        <div class="cell-view">
            <div class="cell-spant">
            We come from all over the world, understand flowers, love flowers, focus on flowers, and use the fragrant flowers picked from the world's first-class rose gardens to weave eternal love. From being given to friends to passing on to the world, ROSEONLY is born for love and blooms under the glitz. It is our ultimate pursuit of beauty; conveying the belief of love in a luxurious manner is our eternal mission.
            </div>
            <div class="cell-spant">We are looking for people who have the same dream as us. If you are: a creative florist, a programming geek, an excellent website product manager and a planner and promoter; please send your resume to hrroseonly.com ROSEONLY is looking forward to hearing from you join in.</div>
        </div>

    </div>
    `
}
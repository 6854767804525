export default {
    html: `
    <div class="right-cell">
        <div class="cell-title">一 配送服务</div>
        <div class="cell-view">
            <div class="cell-spant">可选顺丰联邦速运、专卖店自提、24小时速递。</div>
            <div class="cell-spant">• 顺丰联邦速运：运费由ROSEONLY承担（特殊礼品除外）。</div>
            <div class="cell-spant">订单支付成功之后，ROSEONLY将按您填写的期望送达日期安排发货。 </div>
            <div class="cell-spant">一线及二线城市，ROSEONLY将于您期望日期前24小时内发出。</div>
            <div class="cell-spant">三线城市及其它城市，roseonly将于您期望日期前48小时内发出。</div>
        </div>

        <div class="cell-view">
            <div class="cell-spant">• 专卖店自提：若您所在城市有ROSEONLY专卖店，您可以选择官网下单-专卖店自提。</div>
            <div class="cell-spant">详情请咨询当地ROSEONLY专卖店或ROSEONLY客服。</div>
        </div>

        <div class="cell-view">
            <div class="cell-spant">• 24小时速递：若您所在城市有ROSEONLY专卖店，您可以自费选择“24小时速递”服务。</div>
            <div class="cell-spant">当日14:00之前下单并完成支付，当日可送达。</div>
            <div class="cell-spant">当日14:00-24:00下单并完成支付，次日送达。</div>
        </div>

        <div class="cell-view">
            <div class="cell-spant">• 24小时速递：若您所在城市有ROSEONLY专卖店，您可以自费选择“24小时速递”服务。</div>
            <div class="cell-spant">当日14:00之前下单并完成支付，当日可送达。</div>
            <div class="cell-spant">当日14:00-24:00下单并完成支付，次日送达。</div>
        </div>

        <div class="cell-view">
            <div class="cell-spant">*配送服务特别说明：</div>
            <div class="cell-spant">• 订单确认：在您 下单成功后，ROSEONLY将为您核对订单，并发送确认信息。您可以随时登录ROSEONLY官网，进入订单页面，查询物流状态。</div>
            <div class="cell-spant">•
                订单变更：若您想变更送货地址或时间，可以登录ROSEONLY官网，进入“个人中心”，在“我的订单”中找到需要修改的订单，若订单显示“未发货”状态，您可以直接点击修改配送信息；若订单显示“发货”状态，则无法接受变更。
            </div>
            <div class="cell-spant">• 配送延误：根据以往经验，99%的订单会准时到达，但是偶尔也会遇到雾霾、雨雪、航班延误等不可控因素，因此无法百分之百的保证在您的期望日抵达，如出现此类情况，请您包涵及谅解。
            </div>
        </div>
    </div>
    `
}
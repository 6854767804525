export default {
    html: `
    <div class="right-cell">
    <div class="cell-view">
        <div class="cell-title">一 在线支付</div>
        <div class="cell-spant">ROSEONLY在线支付可选择快捷支付、网银支付、扫码支付三种方式：</div>
        <div class="cell-spant">1 快捷支付：</div>
        <div class="cell-spant">包含多家银行储蓄卡、信用卡，无需开通网银，有卡即可支付。</div>
        <div class="cell-spant">2 网银支付：</div>
        <div class="cell-spant">支持招商银行、中国银行、中国建设银行、中国工商银行等27家银行网银付款。支付限额以各发卡行限额为准。</div>
        <div class="cell-spant">3 扫码支付：</div>
        <div class="cell-spant">手机浏览器登陆不支持微信付款，手机微信登陆仅支持快捷付款。</div>
    </div>
    <div class="cell-view">
        <div class="cell-title">二 优惠券</div>
        <div class="cell-spant">1 什么是优惠券？</div>
        <div class="cell-spant">ROSEONLY不定期发放的可抵同面值金额或享受一定折扣的券。每张券均有专属使用条件，可在遵循优惠券规定条件下使用，有特殊规定的商品除外。</div>
        <div class="cell-spant">2 如何获取优惠券？</div>
        <div class="cell-spant">ROSEONLY会不定期举行回馈活动，注册用户只要积极参与活动，就有机会获取。优惠券会有客户领取及直接发放两种形式，具体发放形式以活动规则为准。</div>
        <div class="cell-spant">3 如何使用优惠券？</div>
        <div class="cell-spant">当用户将商品加入购物车进行结算时，可使用符合条件的优惠券。</div>
        <div class="cell-spant">4 如何查看优惠券？</div>
        <div class="cell-spant">进入“个人中心”，可通过“我的优惠券”查看可使用、已使用及已过期优惠券。</div>
        <div class="cell-spant">5 优惠券使用规则</div>
        <div class="cell-spant">（1）每笔订单仅限使用一张优惠券，每张优惠券只能使用一次。</div>
        <div class="cell-spant">（2）优惠券需在有效期内使用，过期优惠券无法使用。</div>
        <div class="cell-spant">（3）优惠券不可兑现，如遇退单，退款按照实际支付金额结算，该订单使用的优惠券可退回支付账户。</div>

    </div>
</div>
    `
}
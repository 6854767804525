export default {
    html: `
    <div class="right-cell">
    <div class="cell-view">
        <div class="cell-title">1. Online payment</div>
        <div class="cell-spant">ROSEONLY online payment can choose from three methods: quick payment, online banking payment, and scan code payment:</div>
        <div class="cell-spant">1 Quick payment:</div>
        <div class="cell-spant">It includes multiple bank debit cards and credit cards. There is no need to open online banking and you can pay with the card.</div>
        <div class="cell-spant">2 Online banking payment:</div>
        <div class="cell-spant">Supports online banking payments from 27 banks including China Merchants Bank, Bank of China, China Construction Bank, and Industrial and Commercial Bank of China. The payment limit is subject to the limit of each card issuing bank.</div>
        <div class="cell-spant">3 Scan the QR code to pay:</div>
        <div class="cell-spant">Mobile browser login does not support WeChat payment, and mobile WeChat login only supports quick payment.</div>
    </div>
    <div class="cell-view">
        <div class="cell-title">2 coupons</div>
        <div class="cell-spant">1 What is a coupon?</div>
        <div class="cell-spant">ROSEONLY issues coupons from time to time that can be used to offset the same face value or enjoy a certain discount. Each coupon has exclusive conditions of use and can be used under the conditions specified in the coupon, except for products with special provisions.</div>
        <div class="cell-spant">2 How to get coupons?</div>
        <div class="cell-spant">ROSEONLY will hold feedback activities from time to time, and registered users will have the opportunity to obtain them as long as they actively participate in the activities. There are two forms of coupons: customer collection and direct distribution. The specific distribution form is subject to the event rules.</div>
        <div class="cell-spant">3 How to use coupons?</div>
        <div class="cell-spant">When users add items to their shopping cart for checkout, eligible coupons can be used.</div>
        <div class="cell-spant">4 How to view coupons?</div>
        <div class="cell-spant">Enter "Personal Center" and view available, used and expired coupons through "My Coupons".</div>
        <div class="cell-spant">5 Coupon usage rules</div>
        <div class="cell-spant">(1) Only one coupon can be used per order, and each coupon can only be used once.</div>
        <div class="cell-spant">(2) Coupons must be used within the validity period, expired coupons cannot be used.</div>
        <div class="cell-spant">(3) Coupons cannot be redeemed. In the event of a chargeback, the refund will be settled based on the actual payment amount, and the coupon used for the order can be returned to the payment account.</div>
    </div>
</div>
    `
}
const config = {
    zh_CN: {
        prod: {
            api: 'https://api.roseonly.com.cn',
            test: 'https://www.roseonly.com.cn'
        }
    },

    en_SG: {
        prod: {
            // api: 'http://172.21.240.12:8989/ro-website-web',
            // api: 'http://192.168.31.224:8080/',
            api: 'https://api.roseonly.com/',
            test: 'https://over-api.huakai.com/'
            
        }
    },
}

export default config
export default {
    html: `
    <div class="right-cell">
        <div class="cell-title">Delivery Method</div>
        <div>
            <div class="cell-spant">Optional express transportation, store pick-up, and intra-city express delivery</div>
            <div class="cell-spant">1 Express transportation: In order to ensure the safety and delivery speed of gifts, ROSEONLY will use SF Express or FedEx for delivery, and ROSEONLY will bear the freight.</div>
            <div class="cell-spant">2 Self-pickup at the specialty store: Select the specialty store to pick up the goods when placing an order, and fill in the mobile phone number of the person who picks up the goods.</div>
            <div class="cell-spant">3 City Express (ROSEONLY exclusive express service)</div>
            <div class="cell-spant">Delivery range: designated areas in the same city where the store is located. Delivery time: Orders placed before 14:00 will be delivered on the same day, orders placed after 14:00 will be delivered the next day.</div>
            <div class="cell-spant">Express delivery fee: Free in Beijing, 100 yuan for other cities.</div>
        </div>
    </div>
    `
}
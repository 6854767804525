export default {
    html: `
    <div class="right-cell">
    <div class="cell-view">
        <div class="cell-title">平台退货条例</div>
        <div class="cell-spant">• 任何非ROSEONLY.出售的商品；</div>
        <div class="cell-spant">• 任何未保持完好商品；</div>
        <div class="cell-spant">• 鲜花类商品自实际签收起24小时后，其他商品自实际签收之日起七日后； </div>
        <div class="cell-spant">• 任何因非正常使用、保管或保养不当而导致质量问题的商品；</div>
        <div class="cell-spant">• 无任何质量问题的鲜花系列商品及其他定制类商品；</div>
        <div class="cell-spant">• 因收货人地址、电话号码填错或收货人拒收等原因，造成商品拒签或快递无法正常投送；</div>
        <div class="cell-spant">• 任何到店自提订单，提货后不再支持退换货；</div>
        <div class="cell-spant">• 其他依法、依约定不应办理退换货的。</div>
    </div>

    <div class="cell-view">
        <div class="cell-title">退换货服务</div>
        <div class="cell-spant">• 鲜花属高度易耗类商品，签收时如发现存在严重损耗（如枯萎、花谢），请在签收后24小时内拍照并发起退换货流程。</div>
        <div class="cell-spant">• 要求退换货的，鲜花属高度易耗类商品，签收时如发现存在严重损耗（如枯萎、花谢），请在签收后24小时内拍照并发起退换货流程。</div>
        <div class="cell-spant">• 要求退换货的，请点击“个人中心”，选择左栏的“退货说明”；也可选择连同签收单和订单号一起发往service@roseonly.com等方式发起退换货流程。</div>
        <div class="cell-spant">• 请务必将商品的内带附件、赠品（如有）、保修卡、说明书、发票、检测报告（针对需凭检测报告办理退换货的商品）等随同商品一起退回；如赠品已使用不符合退换货，主商品已退货，需扣
            除赠品金额。需更换的商品退回后经确认完整的，将会在3个工作日内重新发货。</div>
        <div class="cell-spant">• 由于商品本身质量问题造成的退换所产生的运费由ROSEONLY.承担。</div>
        <div class="cell-spant">• 珠宝类商品采用的宝石均为天然宝石，含有包裹体、生长纹、色带、气泡、矿物杂质等，属于天然宝石的物理特征，不属于质量问题。</div>
        <div class="cell-spant">• 商品及信息仅供参考，因拍摄灯光及不同显示器色差等问题可能造成商品图片与实物有一定色差，一切请以实物为准。</div>
    </div>

    <div class="cell-view">
        <div class="cell-title">售后服务说明</div>
        <div class="cell-spant">• ROSEONLY.珠宝有6个月保修期，保修期间享受免费维修、养护服务；我们还将为您提供终身专业翻新服务(一年仅限一次）；</div>
        <div class="cell-spant">• 凭购物小票、发票可以进行免费的指圈更改项目（如不可更改戒圈的产品建议重新定做）；</div>
        <div class="cell-spant">• 保修期后，如遇脱落、断裂褪色、氧化等问题，可提供专业有偿维修服务，具体细则可咨询客服。</div>
    </div>
</div>
    `
}
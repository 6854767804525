export default {
    html: `
    <div class="right-cell">
        <div class="cell-view">
            <div class="cell-title">To all partners who support ROSEONLY:</div>
            <div class="cell-spant">In order to ensure that every customer can buy genuine "ROSEONLY", our company now declares as follows:</div>
            <div class="cell-spant">1. The sales channels of "ROSEONLY" series products are limited to:</div>
            <div class="cell-spant">Online sales channels:</div>
            <div class="cell-spant">(1) Brand official website</div>
            <div class="cell-spant">Official website name: ROSEONLY.</div>
            <div class="cell-spant">Official website: http://www.roseonly.com.cn</div>
            <div class="cell-spant">(2) Tmall</div>
            <div class="cell-spant">ROSEONLY. Official flagship store: https://roseonly.tmall.com</div>
            <div class="cell-spant">ROSEONLY.Official jewelry flagship store: https://roseonlyzb.tmall.com</div>
            <div class="cell-spant">(3) JD.com</div>
            <div class="cell-spant">ROSEONLY. Official flagship store: http://roseonly.jd.com</div>
            <div class="cell-spant">ROSEONLY. Jewelry official flagship store: http://roseonlyzb.jd.com</div>
            <div class="cell-spant">ROSEONLY. Self-operated flagship store: http://mall.jd.com/index-1000014661.html</div>
            <div class="cell-spant">(4) Official WeChat</div>
            <div class="cell-spant">ROSEONLY. WeChat public account name: ROSEONLY promise</div>
            <div class="cell-spant">(5) Official Weibo</div>
            <div class="cell-spant">ROSEONLY. Sina Weibo: ROSEONLY promise, ROSEONLY customized for love</div>
            <div class="cell-spant">(6) Secoo</div>
            <div class="cell-spant">ROSEONLY.Brand name:ROSEONLY.NOVO</div>
            <div class="cell-spant">(7) Xiaohongshu APP</div>
            <div class="cell-spant">ROSEONLY.Business name: ROSEONLY official brand store</div>
            <div class="cell-spant">Offline sales channels:</div>
            <div class="cell-spant">For the address of ROSEONLY. national stores, please go to the official website to check:</div>
            <div class="cell-spant">For the address of ROSEONLY. nationwide stores, please go to the official website: <a href="http://www.roseonly.com.cn/about/shitidian.html">http://www.roseonly.com.cn/about/shitidian.html</a>
            </div>
            <div class="cell-spant">
            2. Our company sells "ROSEONLY" series products through direct sales. Except for the sales channels listed in this statement, our company has not authorized any individual or company to sell any "ROSEONLY" products through any other channels. The products sold through this channel are all fake and shoddy products.</div>
            <div class="cell-spant">3. If you find any information about the sale of counterfeit and shoddy products, please feel free to report it to our company. The reporting hotline is: [400-1314-520 or 010-85888791]. At the same time, if you purchased products through WeChat merchants, you can click on the counterfeit seller's WeChat account and "complain" in "Information Settings".</div>
        </div>
    </div>
    `
}
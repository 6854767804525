import { Process_CN, Payment_CN, Question_CN, Refunds_CN, Delivery_CN, Service_CN, Channel_CN, Join_CN} from './Footer_Lang/index'


export default {
    Login: {
      username: '请输入用户名',
      password: '请输入密码',
      Username: '用户名',
      Password: '密码',
      Email: '邮箱地址'
    },
    form: {
      submit: '提交'
    },
    Footer: {
        Shopping: '购物帮助',
        About: '关于公司',
        AboutRo: '关于ROSEONLY',
        Purchase: '购买流程',
        Payment: '支付方式',
        Question: '常见问题',
        Refund: '退货',
        Delivery: '配送方式',
        Service: '配送服务',
        Brand: '品牌介绍',
        Channel: '销售渠道',
        Aboutus: '联系我们',
        Joinus: '加入我们',
        Maintenance: '保养与维修',
        Finger: '指圈测量',
        MicroBlog: '微博',
        WeChat: '微信',
        TikTok: '抖音',
        RedBook: '小红书',
        ChangeLocation: '变更位置'
    },
    List: {
        Color: '颜色',
        Size: '尺寸',
        Series: '系列',
        Design: '形状'
    },
    ProcessLang: Process_CN,
    PaymentLang: Payment_CN,
    QuestionLang: Question_CN,
    RefundsLang: Refunds_CN,
    DeliveryLang: Delivery_CN,
    ServiceLang: Service_CN,
    ChannelLang: Channel_CN,
    JoinLang: Join_CN,
    Location: {
      Allother: 'All other locations',
      International: '国际站'
    }
}
import router from '../router/router'
import Cookies from 'js-cookie'


const whiteList = ['/common/orders', '/common/myinfo', '/common/address']


router.beforeEach((to, from, next) => {

    let toPath = whiteList.includes(to.path)
    /* 
    
        1. 如果是白名单，并登录直接进入，未登录跳登录页
        2. 不是白名单直接进入
    */
    if (toPath) {
        if (Cookies.get('uid')) {
            next()
        } else {
            next({ path: '/login' })
        }
    } else {
        next()

    }

})
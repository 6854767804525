import { createRouter, createWebHashHistory } from 'vue-router'
import { PageView, TabbarView } from '../components/layouts'

const initRoutes: Array<any> = [
    {
        path: '/',
        name: 'Home',
        redirect: '/home',
        component: TabbarView,
        children: [
            {
                path: '/home',
                name: 'Home',
                meta: {
                    keepAlive: true
                },
                component: () => import('../views/Tabbar/Home.vue'),
            },
            {
                path: '/menu',
                name: 'Menu',
                meta: {
                    keepAlive: true
                },
                component: () => import('@/views/Tabbar/Menu.vue'),
            },
            {
                path: '/my',
                name: 'My',
                component: () => import('@/views/Tabbar/My.vue'),
            },
            {
                path: '/list/:id',
                name: 'List',
                meta: {
                    keepAlive: true,
                    isBack: false
                },
                component: () => import('@/views/pages/List-PC.vue'),
            },
            {
                path: '/info/:id',
                name: 'Info',
                component: () => import('@/views/pages/Info-PC.vue'),
            },
            {
                path: '/login',
                name: 'Login',
                component: () => import('@/views/pages/Login.vue'),
            },
            {
                path: '/register',
                name: 'Register',
                component: () => import('@/views/pages/Register.vue'),
            },
            {
                path: '/common',
                name: 'Common',
                redirect: '/common/orders',
                component: () => import('@/views/pages/Common/Common.vue'), 
                children: [{
                    path: '/common/orders',
                    name: 'OrderList',
                    component: () => import('@/views/pages/Common/OrderList.vue'),
                },
                {
                    path: '/common/address',
                    name: 'Address',
                    component: () => import('@/views/pages/Common/Address.vue'),
                },
                {
                    path: '/common/myinfo',
                    name: 'MyInfo',
                    component: () => import('@/views/pages/Common/MyInfo.vue'),
                },]
            },
            {
                path: '/footer',
                name: 'Footer',
                redirect: '/footer/process',
                component: () => import('@/views/pages/Footer/Footer.vue'),
                children: [{
                    path: '/footer/process',
                    name: 'Process',
                    component: () => import('@/views/pages/Footer/Process.vue'),
                },{
                    path: '/footer/question',
                    name: 'Question',
                    component: () => import('@/views/pages/Footer/Question.vue'),
                },
                {
                    path: '/footer/about',
                    name: 'About',
                    component: () => import('@/views/pages/Footer/Aboutus.vue'),
                },
                {
                    path: '/footer/maintenance',
                    name: 'Maintenance',
                    component: () => import('@/views/pages/Footer/Maintenance.vue'),
                },
                {
                    path: '/footer/join',
                    name: 'Joinus',
                    component: () => import('@/views/pages/Footer/Joinus.vue'),
                },  {
                    path: '/footer/delivery',
                    name: 'Delivery',
                    component: () => import('@/views/pages/Footer/Delivery.vue'),
                },{
                    path: '/footer/service',
                    name: 'Service',
                    component: () => import('@/views/pages/Footer/Service.vue'),
                },
                {
                    path: '/footer/brand',
                    name: 'Brand',
                    component: () => import('@/views/pages/Footer/Brand.vue'),
                },{
                    path: '/footer/refund',
                    name: 'Refund',
                    component: () => import('@/views/pages/Footer/Refund.vue'),
                },{
                    path: '/footer/channel',
                    name: 'Channel',
                    component: () => import('@/views/pages/Footer/Channel.vue'),
                },{
                    path: '/footer/payment',
                    name: 'Payment',
                    component: () => import('@/views/pages/Footer/Payment.vue'),
                },{
                    path: '/footer/finger',
                    name: 'Finger',
                    component: () => import('@/views/pages/Footer/Finger.vue'),
                }]
            },
        ]
    },
    {
        path: '/carts',
        name: 'Carts',
        redirect: '/carts',
        component: PageView,
        children: [{
            path: '/carts',
            name: 'Carts',
            meta: {
                keepAlive: true
            },
            component: () => import('@/views/Tabbar/Carts.vue'),
        }]
    },

    // {
    //     path: '/list',
    //     name: 'List',
    //     redirect: '/list',
    //     component: PageView,
    //     children: [
    //         {
    //             path: '/list',
    //             name: 'List',
    //             component: () => import('@/views/pages/List.vue'),
    //         },
    //         {
    //             path: '/info',
    //             name: 'Info',
    //             component: () => import('@/views/pages/Info.vue'),
    //         }
    //     ]
    // },
    {
        path: '/order',
        name: 'Order',
        redirect: '/order',
        component: TabbarView,
        children: [
            {
                path: '/order',
                name: 'Order',
                component: () => import('@/views/pages/Order.vue'),
            },
            {
                path: '/find',
                name: 'FindPwd',
                component: () => import('@/views/pages/FindPwd.vue'),
            }
        ]
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes: initRoutes,
})


export default router


import { Process_EN, Payment_EN,Question_EN, Refunds_EN, Delivery_EN, Service_EN, Channel_EN, Join_EN } from './Footer_Lang/index'

export default {
    Login: {
        username: 'Please enter the user name',
        password: 'Please enter your password',
        Username: 'Username',
        Password: 'Password',
        Email: 'Email address'
    },
    form: {
        'submit': 'Submit'
    },
    Footer: {
        Shopping: 'Shopping Assistance',
        About: 'About ROSEONLY',
        AboutRo: 'About the ROSEONLY',
        Purchase: 'How to purchase',
        Payment: 'Payment Method',
        Question: 'Frequently Asked Questions',
        Refund: 'Refunds',
        Delivery: 'Shipping & Delivery',
        Service: 'Delivery Service',
        Brand: 'About ROSEONLY',
        Channel: 'Sales Channel',
        Aboutus: 'Contact Us',
        Joinus: 'Join Us',
        Maintenance: 'Maintenance Explain',
        Finger: 'Finger Circle Measurement',
        MicroBlog: 'MicroBlog',
        WeChat: 'WeChat',
        TikTok: 'TikTok',
        RedBook: 'RedBook',
        ChangeLocation: 'Change Location',
        Latestfrom: 'Contact Us'
    },
    List: {
        Color: 'Color',
        Size: 'Size',
        Series: 'Series',
        Design: 'Design'
    },
    ProcessLang: Process_EN,
    PaymentLang: Payment_EN,
    QuestionLang: Question_EN,
    RefundsLang: Refunds_EN,
    DeliveryLang: Delivery_EN,
    ServiceLang: Service_EN,
    ChannelLang: Channel_EN,
    JoinLang: Join_EN,
    Location: {
        ChangeLocation: 'Select Location',
        Allother: 'All other locations',
        International: 'International',
        chinase: '中国大陆'
    }

}
<template>
  <div id="main">
    <div class="tabber-view">
      <div>
        <LocationTips />
        <Header />
      </div>
      <div class="page-box-body">
        <router-view v-slot="{ Component }">
          <KeepAlive :include="includeList">
            <component :is="Component" />
          </KeepAlive>
        </router-view>
      </div>
      <!-- <Tabber /> -->
      <Footer />
      <!-- <ChangeLocation /> -->
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, watch, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import Header from "../header/Header.vue";
import Tabber from "../header/Tabber.vue";
import Footer from "../header/Footer.vue";
import ChangeLocation from "../homes/ChangeLocation.vue";
import LocationTips from "../header/LocationTips.vue";

export default defineComponent({
  name: "TabbarView",
  components: { Header, Tabber, Footer, ChangeLocation, LocationTips },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const data = reactive({
      includeList: ["Home", "List"],
    });
    let isFixed = ref(false)

    watch(route, () => {
      if (route.meta.keepAlive && !data.includeList.indexOf(route.name) === -1) {
        data.includeList.push(route.name);
      }
    });

    const handleGoHome = () => {
      router.replace({
        name: "Home",
      });
    }



    return {
      route,
      ...toRefs(data),
      isFixed,
      handleGoHome,
    };
  },
});
</script>

<style lang="scss" scoped></style>
export default {
    html: `
    <div class="right-cell">
        <div class="cell-view">
            <div class="cell-spant">
                我们来自于世界各地，懂花、爱花、专注于花，用采摘世界一流玫瑰园的芬芳花朵，编织出万世不朽的爱意。从赠予朋友到流转世人，ROSEONLY为爱而生，于浮华之下倾城绽放，是我们对美的极致追求；以奢华之态传递爱的信念，是我们永恒的使命。
            </div>
            <div class="cell-spant">我们正在寻找那些与我们有一样梦想的人,如果你是： 富有创意的花艺师、编程极客、优秀的网站产品经理和策划推广人员；请将简历发到 hrroseonly.com
                ROSEONLY期待您的加入。</div>
        </div>

    </div>
    `
}
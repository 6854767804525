<template>
    <div class="container-pc">
        <div class="footer-pc Global-W">
            <div class="footer-pc-title">
                <div class="HOVERVIEW HOVER footer-location" @click="handleShow">{{ $t('Footer.ChangeLocation') }}
                    <span class="iconfont icon-jiantoushang-down" v-if="!isShowFlag"></span>
                    <span class="iconfont icon-jiantoushang" v-if="isShowFlag"></span>
                </div>
                <!-- <div>ROSEONLY</div> -->
                <div></div>
            </div>
            <div class="footer-pc-top" v-if="isShowFlag">
                <div class="footer-pc-item" v-for="(item, index) in footerData" :key="index">
                    <div class="footer-pc-item-title">
                        <a class="">{{ $t(item.i18n) }}</a>
                    </div>
                    <div class="footer-pc-item-menu" v-for="(cell, index) in item.children" :key="index">
                        <a class="HOVERVIEW HOVER" :href="cell.url">{{ $t(cell.i18n) }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-m">
        <div class="footer-m Global-W">
            <div class="footer-m-title">
                <div class="footer-location" @click="handleShow">{{ $t('Footer.ChangeLocation') }}
                    <span class="iconfont icon-jiantoushang-down" v-if="!isShowFlag"></span>
                    <span class="iconfont icon-jiantoushang" v-if="isShowFlag"></span>
                </div>
                <!-- <div>ROSEONLY</div> -->
                <div></div>
            </div>
            <div class="footer-m-top" v-show="isShowFlag">
                <div class="footer-m-item" v-for="(item, index) in footerData" :key="index">
                    <div class="footer-m-item-title">
                        <a class="">{{ $t(item.i18n) }}</a>
                    </div>
                    <div class="footer-m-item-menu" v-for="(cell, index) in item.children" :key="index">
                        <a class="" :href="cell.url">{{ $t(cell.i18n) }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script setup>
import { defineProps, ref } from "vue";
import { useRouter } from "vue-router"
const router = useRouter()
const props = defineProps({
    data: {
        type: Array,
    },
});

const footerData = ref([
    {
        name: "变更位置",
        i18n: "Location.Allother",
        children: [
            {
                title: "购买流程",
                path: "/footer/process",
                English: 'Purchase Process',
                name: 'Process',
                i18n: "Location.International",
                url: 'https://www.roseonly.com/'
            },
            {
                title: "购买流程",
                path: "/footer/process",
                English: 'Purchase Process',
                name: 'Process',
                i18n: "Location.chinase",
                url: 'https://www.roseonly.com.cn/'
            },
        ],
    },

]);

const isShowFlag = ref(false)


const handleShow = () => {
    isShowFlag.value = !isShowFlag.value
    setTimeout(()=> {
        window.scrollTo(0, document.body.scrollHeight)
    },100)
}


</script>
  
<style lang="scss" scoped>
.container-pc {
    background-color: #414141;
    color: #fff;
}

.footer-view {
    background-color: $globalColor;


}

.footer-pc {
    display: flex;
    flex-direction: column;
    padding: 25px;
    font-size: 15px;

    .logo {
        width: 100%;
        text-align: center;
        height: 50px;
    }

    .footer-pc-title {
        display: flex;
        justify-content: space-between;
        // font-weight: 700;

        .footer-location {
            font-weight: 700;
        }

        .icon-jiantoushang-down,
        .icon-jiantoushang {
            font-size: 15px;
            margin-left: 5px;
            margin-top: 3px;
        }
    }

    .footer-pc-top {
        display: flex;
        margin: 30px 0;
    }

    .footer-pc-bottom {

        // display: flex;
        // justify-content: space-between;
        div {
            margin: 20px 0;
        }
    }

    .footer-pc-item {
        width: 25%;

        .footer-pc-item-title {
            margin: 20px 0;
            font-weight: 700;
        }

        .footer-pc-item-menu {
            margin: 20px 0;
        }
    }
}


.container-m {
    background-color: #414141;
    color: #fff;
    padding-bottom: 1rem;
    .footer-m {
        display: flex;
        flex-direction: column;
        padding: 25px;
        font-size: 15px;

        .logo {
            width: 100%;
            text-align: center;
            height: 50px;
        }

        .footer-m-title {
            display: flex;
            justify-content: space-between;
            // font-weight: 700;

            .footer-location {
                font-weight: 700;
            }

            .icon-jiantoushang-down,
            .icon-jiantoushang {
                font-size: 15px;
                margin-left: 5px;
                margin-top: 3px;
            }
        }

        .footer-m-top {
            display: flex;
            width: 100%;
            margin: 30px 0;
        }

        .footer-m-bottom {
            div {
                margin: 20px 0;
            }
        }

        .footer-m-item {
            width: 100%;

            .footer-m-item-title {
                width: 100%;
                margin: 20px 0;
                font-weight: 700;
            }

            .footer-m-item-menu {
                margin: 20px 0;
            }
        }
    }



}
</style>
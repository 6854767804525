<template>
    <div class="container-pc">
        <div class="container-pc-view">
            <div :class="['container-pc-menu', isFixed ? 'HEADER HEADER-MENU' : '']">
                <div :class="['menu-item', { 'cate-skeleton': isShowSkeleton }]" v-for="(item, index) in menuData"
                    :key="index" @mouseenter="handleShowDropDown(item)" @click="handleGopage(item)"
                    @mouseleave="handleHideDropDown(item)">
                    {{ item.menuName }}

                    <!-- 菜单 -->
                    <!-- <transition name="fade"> -->
                    <div :class="['container-menu-pc', { active: item.active }, isFixed ? 'HEADER' : '']"
                        v-if="item.active">
                        <div class="menu-pc-view Global-W">
                            <div class="menu-pc-view-left">
                                <div class="menu-pc-view-left-item" v-for="(items, index) in activeMenu.childMenus"
                                    :key="index">
                                    <div class="menu-pc-view-left-title">{{ items.menuName }}</div>
                                    <div class="menu-pc-view-left-menuName" v-for="(cell, index) in items.childMenus"
                                        :key="index" @click.stop="handleGopage(cell)">
                                        {{ cell.menuName }}
                                    </div>
                                </div>
                            </div>
                            <div class="menu-pc-view-right">
                                <img :src="item.pcsecondPicture" alt="" v-if="false" />
                            </div>
                        </div>
                    </div>
                    <!-- </transition> -->
                </div>
            </div>
        </div>
    </div>

</template>



<script name="Menu" setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import requestAsync from "../../utils/request";
import useScroll from "../../hooks/useScrollHeader"
const router = useRouter()
const menuData = ref([])
const activeMenu = ref({})
const isShowSkeleton = ref(false)
let { isFixed, InitScroll } = useScroll()
onMounted(() => {
    getMenuData()
})

window.addEventListener('scroll', InitScroll, true)

const getMenuData = async () => {
    const data = await requestAsync({
        baseIp: window.location.origin,
        url: "/en_SG/js/roseonly_menu.json",
    });
    menuData.value = data.childMenus.childMenus;
};

const handleShowDropDown = (item) => {
    if (item.childMenus && item.childMenus.length <= 0) return
    menuData.value.forEach(element => {
        element.active = false
    })

    item.active = true
    activeMenu.value = item;
};

const handleGopage = (info) => {
    console.log('info', info)
    router.replace({
        name: "List",
        params: {
            id: info.id,
        },
    });
    handleHideDropDown()
};

const handleHideDropDown = () => {
    menuData.value.forEach(element => {
        element.active = false
    })

};

</script>


<style lang="scss" scoped>
/* pc */
@media screen and (min-width: 768px) {
    .container-pc-view {
        width: 100%;
        height: 32px;
        color: $globalColor;
        background-color: #fff;
        margin: 20px 0;

        /* 菜单 */
        .container-pc-menu {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            color: $globalColor;

            .menu-item {
                padding: 0 10px;
                margin: 0 10px;
                font-size: 15px;
                font-weight: 700;
                cursor: pointer;
                min-width: 80px;
                height: 32px;
                line-height: 32px;

                .container-menu-pc {
                    position: absolute;
                    top: 32px;
                    left: 0;
                    width: 100%;
                    height: 0;
                    overflow: hidden;
                    z-index: 99;
                    background-color: #f1f1f1;
                    transition: all 0.2s;

                    .menu-pc-view {
                        display: flex;
                        justify-content: space-between;
                        // width: 100%;
                        padding: 50px 0;
                    }

                    .menu-pc-view-left {
                        flex: 1 1;
                        // display: flex;
                        color: $globalColor;

                        .menu-pc-view-left-item {
                            display: flex;
                            align-items: center;
                            margin: 0 40px;
                            cursor: pointer;
                            height: 50px;

                            // background-color: pink;
                            .menu-pc-view-left-title {
                                width: 80px;
                                font-size: 15px;
                                font-weight: 700;
                                margin-right: 20px;
                            }

                            .menu-pc-view-left-menuName {
                                // display: flex;
                                font-size: 15px;
                                margin: 0 20px;
                                // flex-wrap: wrap;
                                font-weight: 500;
                                white-space: nowrap;
                            }

                            .menu-pc-view-left-menuName:hover {
                                color: $SelectColor;
                            }
                        }
                    }

                    /* 右侧图片 */
                    .menu-pc-view-right {
                        width: 350px;
                    }
                }

                .active {
                    height: auto;
                }


            }
        }
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 1s ease;
    }

    /* 进入前的状态 */
    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }


}
</style>
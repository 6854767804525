export default {
    html: `
    <div class="right-cell">
    <div class="cell-view">
        <div class="cell-title">Q：忘记密码怎么办？</div>
        <div class="cell-spant">A：请点击官网右上角“登陆”，进入界面后，点击“忘记密码”，按照提示操作；或通过手机端“登录”找回密码，进入界面后，输入“注册</div>
    </div>

    <div class="cell-view">
        <div class="cell-title">Q：怎样用微信支付？</div>
        <div class="cell-spant">A：请使用电脑端订购，在支付时，可选择“扫码支付”，扫码支付支持微信付款。</div>
    </div>


    <div class="cell-view">
        <div class="cell-title">Q：商品一般提前多久预订？如果要的急，可以临时加单吗？</div>
        <div class="cell-spant">A：ROSEONLY礼品最早可接受提前3个月预定；若正常预定，每天14:00前下单可当天发货，以便您尽早收到；若您所在城市有ROSEONLY专卖店，您可以在官网下单，选择“专卖
            店自提”，或使用“24小时速递”当天送达。如果您急需收到礼品，可致电ROSEONLY客服400-1314-520（周一至周日9:00-21:00），我们会酌情为您解决。</div>
    </div>



    <div class="cell-view">
        <div class="cell-title">Q：如何查看祝福语？ </div>
        <div class="cell-spant">A：当您在线下单留下“爱语”，收礼人可以使用手机QQ
            、微信、手机360、支付宝等扫描软件，扫描二维码后输入订单收礼人手机号码，通过验证后查看爱语。同时，可以关 注官方微信
            “ROSEONLY诺誓，点击“专属服务”-“爱语查询”，永久随时查询爱语。</div>
    </div>


    <div class="cell-view">
        <div class="cell-title">Q：配送能按照我的要求准时送达吗？</div>
        <div class="cell-spant">A：ROSEONLY与顺丰、联邦快递合作，且发货后有专人随时跟进配送进程，若无特殊情况，99%订单将于客户期望到达日期当日18:00前派送；偶尔出现如雾霾、雨雪、航班延误
            等不可控因素影响物流配送，敬请谅解。若担心稍有延迟，客户可以选择将期望到达日期提前。</div>
    </div>

    <div class="cell-view">
        <div class="cell-title">Q：鲜花玫瑰和永生玫瑰有什么区别？</div>
        <div class="cell-spant">A：鲜花玫瑰为新鲜采摘的顶级厄瓜多尔玫瑰。这些玫瑰在离天堂最近的玫瑰花田生长，沐浴365天的阳光和雨水，我们精选在花园中花枝挺拔能至1.5米、花蕾高达3英寸、每
            朵花如心脏般大小的玫瑰。ROSEONLY只选全世界百里挑一的鲜花奢侈品。永生玫瑰，则是将厄瓜多尔鲜花玫瑰，经过108道特殊工艺、长达60天雕琢，使其能够长久绽放、
            永不凋零。您可以根据喜好挑选。</div>
    </div>


    <div class="cell-view">
        <div class="cell-title">Q：请问如何取消订单办理退款？</div>
        <div class="cell-spant">A：若礼品未发货，您可以登录官网“个人中心”自行申请退款，工作人员会在48小时之内操作退款，退款金额到账时间以各银行为准。若订单已发货，则无法取消订单，敬请 谅解。</div>
    </div>


    <div class="cell-view">
        <div class="cell-title">Q：收到的礼品不喜欢是否可以办理退换货？</div>
        <div class="cell-spant">A：由于ROSEONLY礼品的特殊性，若无任何质量问题，因个人喜好（气味，色泽、型号，外观等）要求的退换货无法受理，敬请谅解。</div>
    </div>

    <div class="cell-view">
        <div class="cell-title">Q：我需要发票怎么办？</div>
        <div class="cell-spant">
            A：请您将订单编码、发票抬头、发票收件地址及联系方式发送给ROSEONLY官方微信“ROSEONLY诺誓”，点击“专属服务”-“在线客服”提交以上信息，ROSEONLY会在收到信息
            后尽快为您办理。</div>
    </div>

    <div class="cell-view">
        <div class="cell-title">Q：为什么我收到的花跟网上的图片不太一样？</div>
        <div class="cell-spant">A：花为纯天然生长，每一朵花的形状、大小、色泽均有差别；其次，因为拍摄光线、角度、电脑浏览器差别，图片也可能会产生些许误差。</div>
    </div>

    <div class="cell-view">
        <div class="cell-title">Q：天猫购买的产品是正品吗？</div>
        <div class="cell-spant">A：ROSEONLY官方销售平台有：ROSEONLY官网、天猫ROSEONLY旗舰店，京东ROSEONLY旗舰店，您可以放心购买。</div>
    </div>
</div>
    `
}
import { createApp } from 'vue'
import App from './App.vue'
import router from './router/router'

/* 引入vuex */
import store from './store/store'
import './utils/permission'


import '@/assets/css/common.css';
import '@/assets/css/iconfont.css';
import '@/assets/css/font-size.css';
import '@/assets/css/skeleton.css';
import "@/assets/iconfont/iconfont-weapp-icon.css"


/* 引入media样式 */
import '@/assets/scss/media-pc.scss'
import '@/assets/scss/media-m.scss'


import i18n from './i18n/index'

// import './utils/pcRem'

import { 
    Button, 
    Swipe,
    SwipeItem, 
    SwipeCell,
    Card,
    SubmitBar, 
    Checkbox, 
    CheckboxGroup, 
    ActionBar,
    ActionBarIcon, 
    ActionBarButton, 
    Sticky, 
    List, 
    Tab, 
    Tabs, 
    Popup, 
    Stepper, 
    Notify,
    Collapse,
    CollapseItem,
    Lazyload,
    BackTop,
    Picker,
    Field,
    DatePicker,
    RadioGroup, 
    Radio
} from "vant";
import { Image as VanImage } from 'vant';

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'


import { Carousel, Menu, Dropdown } from 'ant-design-vue';


const app = createApp(App)
app.use(Button)
app.use(Swipe)
app.use(SwipeItem)
app.use(SwipeCell)
app.use(Card)
app.use(SubmitBar)
app.use(Checkbox);
app.use(CheckboxGroup);
app.use(ActionBar);
app.use(ActionBarIcon);
app.use(ActionBarButton);
app.use(Sticky);
app.use(List);
app.use(Tab);
app.use(Tabs);
app.use(Popup);
app.use(Stepper);
app.use(Collapse);
app.use(CollapseItem);
app.use(VanImage);
app.use(Lazyload);
app.use(BackTop);
app.use(Notify);
app.use(router)
app.use(store)
app.use(ElementPlus)
app.use(Field) 
app.use(Picker)
app.use(DatePicker)
app.use(Radio);
app.use(RadioGroup);
app.use(Carousel)
app.use(Menu)
app.use(Dropdown)


app.use(i18n)


app.mount('#app')

<template>
  <!-- pc -->
  <div class="container-pc">
    <div class="footer-pc Global-W">
      <div class="footer-pc-top">
        <div class="footer-pc-item" v-for="(item, index) in footerData" :key="index">
          <div class="footer-pc-item-title">
            <a class="">{{ $t(item.i18n) }}</a>
          </div>
          <div class="footer-pc-item-menu" v-for="(cell, index) in item.children" :key="index"
            @click="handleGopage(cell)">
            <a class="HOVERVIEW HOVER HOVER-F">{{ $t(cell.i18n) }}</a>
          </div>

          <div class="" v-if="item.type == 'Custom'">
            <div class="footer-text">If you have any questions or comments about us, you may email us at: <p>service@roseonly.com </p></div>

            <div class="footer-text">Product Enquiries & After-Sale Service: <p>service@roseonly.com</p>
            </div>
            <div class="footer-text">Partnership & Collaboration: <p>market-global@roseonly.com</p>
            </div>
            <div class="iconfont-all">
              <a :class="['iconfont iconfont-all', item.icon]" :href="item.link" v-for="(item, index ) in linkData"
                :key="index" target="_blank"></a>
            </div>

          </div>

        </div>
      </div>

      <!-- <div class="footer-pc-bottom">
        <div>
          <a class="HOVERVIEW HOVER" href="">诺誓（北京）商业股份有限公司</a>
        </div>
        <div>
          <a class="HOVERVIEW HOVER" href="">京公网安备11010502039850</a>
        </div>
        <div>
          <a class="HOVERVIEW HOVER" href="">京ICP备13007738号</a>
        </div>
        <div>
          <a class="HOVERVIEW HOVER" href="">营业执照副本</a>
        </div>
      </div> -->
    </div>
  </div>

  <!-- m -->
  <div class="container-m">
    <div class="footer-m">
      <van-collapse v-model="activeM">
        <van-collapse-item :title="$t(item.i18n)" :name="index" v-for="(item, index) in footerData" :key="index">
          <div class="menu-m-item" v-for="(items, index) in item.children" :key="index">
            <div class="menu-m-title" @click="handleGopage(items)">{{ $t(items.i18n) }}</div>
          </div>
          <div class="" v-if="item.type == 'Custom'">
            <div class="footer-text">If you have any questions or comments about us, you may email us at: <p>service@roseonly.com </p>
            </div>
            <div class="footer-text">Product Enquiries & After-Sale Service: <p>service@roseonly.com</p>
            </div>
            <div class="footer-text">Partnership & Collaboration: <p>market-global@roseonly.com</p>
            </div>
            <div class="iconfont-all">
              <a :class="['iconfont iconfont-all', linkitem.icon]" :href="linkitem.link"
                v-for="(linkitem, index ) in linkData" :key="index" target="_blank"></a>
            </div>
          </div>
        </van-collapse-item>
      </van-collapse>
      <!-- <div class="footer-m-bottom">
        <div>
          <a class="HOVERVIEW HOVER" href="">诺誓（北京）商业股份有限公司</a>
        </div>
        <div>
          <a class="HOVERVIEW HOVER" href="">京公网安备11010502039850</a>
        </div>
        <div>
          <a class="HOVERVIEW HOVER" href="">京ICP备13007738号</a>
        </div>
        <div>
          <a class="HOVERVIEW HOVER" href="">营业执照副本</a>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router"
const router = useRouter()
const footerData = ref([
  {
    name: "购物帮助",
    i18n: "Footer.Shopping",
    children: [
      {
        title: "购买流程",
        path: "/footer/process",
        English: 'Purchase Process',
        name: 'Process',
        i18n: "Footer.Purchase"
      },
      {
        title: "配送方式",
        path: "/footer/delivery",
        name: 'Delivery',
        English: 'Delivery Method',
        i18n: "Footer.Delivery"

      },
      {
        title: "退货",
        path: "/footer/refund",
        name: 'Refund',
        English: "Refunds",
        i18n: "Footer.Refund"

      },
      // {
      //   title: "保养与维修",
      //   name: 'Maintenance',
      //   path: "/footer/maintenance",
      //   English: "Maintenance Explain",
      //   i18n: "Footer.Maintenance"

      // },
      {
        title: "常见问题",
        name: 'Question',
        path: "/footer/question",
        English: 'Frequently Asked Questions',
        i18n: "Footer.Question"

      },
      // {
      //   title: "指圈测量",
      //   name: 'Finger',
      //   path: "/footer/finger",
      //   English: "Finger Circle Measurement",
      //   i18n: "Footer.Finger"

      // },
    ],
  },
  {
    name: "关于公司",
    i18n: "Footer.About",
    children: [
      {
        title: "销售渠道",
        path: "/footer/channel",
        name: 'Channel',
        English: 'Marketing Channel',
        i18n: "Footer.Channel"

      },
      {
        title: "联系我们",
        name: 'About',
        path: '/footer/about',
        English: "Contact us",
        i18n: "Footer.Aboutus"

      },
      {
        title: "加入我们",
        name: 'Joinus',
        path: '/footer/join',
        English: "Join Us",
        i18n: "Footer.Joinus"
      },
    ],
  },
  // {
  //   name: "关于ROSEONLY",
  //   i18n: "Footer.AboutRo",
  //   children: [
  //     // {
  //     //   name: "微博",
  //     //   path: "",
  //     //   i18n: "Footer.MicroBlog"
  //     // },
  //     // {
  //     //   name: "微信公众号",
  //     //   path: "",
  //     //   i18n: "Footer.WeChat"

  //     // },
  //     // {
  //     //   name: "抖音",
  //     //   path: "",
  //     //   i18n: "Footer.TikTok"

  //     // },
  //   ],
  // },
  {
    name: "关于ROSEONLY",
    i18n: "Footer.Latestfrom",
    type: 'Custom',
    children: [
      // {
      //   name: "微博",
      //   path: "",
      //   i18n: "Footer.MicroBlog"
      // },
      // {
      //   name: "微信公众号",
      //   path: "",
      //   i18n: "Footer.WeChat"
      // },
      // {
      //   name: "抖音",
      //   path: "",
      //   i18n: "Footer.TikTok"
      // },
    ],
  },
  {
    name: "更换地区",
    i18n: "Location.ChangeLocation",
    children: [
      {
        title: "国际站",
        name: 'International',
        path: '',
        English: "International",
        i18n: "Location.International",
        href: 'https://www.roseonly.com/'
      },
      {
        title: "中国大陆",
        name: 'chinase',
        path: '',
        English: "chinase",
        i18n: "Location.chinase",
        href: 'https://www.roseonly.com.cn/'
      },
    ],
  }
]);


const linkData = ref([{
  name: 'FB',
  link: 'https://www.facebook.com/roseonlyofficial',
  icon: 'icon-facebook-fill'
}, {
  name: 'IG',
  link: 'https://www.instagram.com/roseonlyofficial ',
  icon: 'icon-instagram-fill'

}, {
  name: '@',
  link: 'https://www.threads.net/@roseonlyofficial',
  icon: 'icon-threads-fill'

},
{
  name: 'twitter',
  link: 'https://www.twitter.com/roseonlyworld',
  icon: 'icon-tuite'

},
{
  name: 'youtube',
  link: 'https://www.youtube.com/@roseonly.official',
  icon: 'icon-Youtube-fill'

},
{
  name: 'tiktok',
  link: 'https://www.tiktok.com/@roseonlyofficial',
  icon: 'icon-tiktok'

},
])


const activeM = ref(["1"]);
const handleGopage = (info) => {
  if (info.name == 'chinase' || info.name == 'International') {
    window.location.href = info.href
  }
  router.push({
    path: info.path
  })
}
</script>

<style lang="scss" scoped>
.container-pc {
  width: 100%;
  color: #cecece;
  background-color: $globalColor;
}

.footer-pc {
  padding: 25px;
  font-size: 15px;

  .footer-pc-top {
    display: flex;
    margin: 30px 0;


    .iconfont-all {
      display: flex;
      justify-content: space-between;
      width: 100%;

      margin: 20px 0;

      .iconfont-all {
        font-size: 35px;
      }
    }


  }

  .footer-pc-bottom {

    // display: flex;
    // justify-content: space-between;
    div {
      margin: 20px 0;
    }
  }

  .footer-pc-item {
    width: 25%;

    .footer-pc-item-title {
      color: #797979;
      margin: 20px 0;
      font-weight: 700;
    }

    .footer-pc-item-menu {
      margin: 20px 0;
    }
  }
}

.footer-m {
  padding: 0.5rem 0;

  .menu-m-item {
    margin: 0.2rem 0;
  }

  .iconfont-all {
    display: flex;
    justify-content: space-between;
    width: 100%;

    margin: 20px 0;

    .iconfont-all {
      font-size: 35px;
    }
  }

  .footer-m-bottom {
    padding: 0 0.25rem;
    font-size: 12px;

    div {
      margin: 0.2rem 0;
    }
  }
}

.footer-text {
  margin: 15px 0;
}
</style>
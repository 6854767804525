<template>
  <!-- pc -->
  <div :class="['container-pc', isFixed ? 'HEADER' : '']">
    <div :class="['container-pc-view Global-W']">
      <div class="login-pc" @click="handleGoHome"></div>
      <!-- <div class="container-pc-menu">
        <div :class="['menu-item', { 'cate-skeleton': isShowSkeleton }]" v-for="(item, index) in menuData" :key="index"
          @mouseenter="handleShowDropDown(item)" @click="handleGopage(item)">
          {{ item.menuName }}
        </div>
      </div> -->
      <div class="container-pc-btns">
        <!-- <div class="btns-icon iconfont icon-fangdajing CURSOR"></div> -->
        <!-- <div class="btns-icon">
          <el-dropdown>
            <span class="el-dropdown-link"> 文 </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="handleChangeLang('ch')">简体中文</el-dropdown-item>
                <el-dropdown-item @click="handleChangeLang('en')">English</el-dropdown-item>
              </el-dropdown-menu>
            </template>
</el-dropdown>
</div> -->
        <div class="btns-text CURSOR iconfont icon-yonghu" @mouseenter="handleShowLogin" @mouseleave="handleShowLogin">
          <div :class="['login-view', isShowLoginFlag ? 'show-login-view' : '']">
            <div class="login-view-top" v-if="!store.state.isLoginFlag">
              <div @click="handleGoPageAndName('Login')">Login</div>
              <div class="login-view-he"></div>
              <div @click="handleGoPageAndName('Register')">Register Account</div>
            </div>
            <div class="login-view-top" v-if="store.state.isLoginFlag">
              <div @click="handleGoPageAndName('OrderList')">Profile Page</div>
              <div class="login-view-he"></div>
              <div @click="handleLoginOut">Login Out</div>
            </div>
            <!-- <div class="login-view-bottom"></div> -->

          </div>
        </div>
        <div class="btns-text CURSOR iconfont icon-gouwudai" @click="handleGoPageAndName('Carts')"></div>
        <div class="btns-text CURSOR" @click="handleGoBrand">Brand story</div>
      </div>

    </div>


  </div>

  <div class="container-pc login" @click="handleGoHome">
    <img src="https://www.roseonly.com.cn/images/logo1.png" alt="">
  </div>
  <Menu v-if="isShowMenuRouter" />

  <!-- pc -->
  <!-- 移动端 -->
  <div :class="['container-m', isFixed ? 'HEADER' : '']">
    <div class="container-m-view">
      <div class="logo-m" @click="handleGoHome"></div>
      <div class="container-m-btns">
        <div class="icon-login" @click="handleGoPageAndName('Login')" v-if="!store.state.isLoginFlag">Login</div>
        <div class="iconfont icon-gerenzhongxin" v-if="store.state.isLoginFlag"
          @click="handleGoPageAndName('OrderList')">
        </div>
        <div class="iconfont icon-liebiao2" @click="isShowModel = !isShowModel"></div>
      </div>
    </div>

    <!-- 弹出层 -->
    <van-popup v-model:show="isShowModel" position="left" :style="{ width: '80%', height: '100%' }">
      <div class="popup-view">
        <div class="logo-m"></div>
        <div class="iconfont icon-guanbi1" @click="isShowModel = !isShowModel"></div>
      </div>

      <div class="collapse-view">
        <div class="collapse-item" v-for="(item, index) in menuData" :key="index">
          <div class="collapse-item-title">
            <div @click="handleGopage(item)">
              {{ item.menuName }}
            </div>
            <div v-if="item.childMenus && item.childMenus.length > 0"
              :class="['iconfont', 'jaintou', item.checked ? 'icon-jiantoushang' : 'icon-jiantoushang-down']"
              @click="handleShowMenuItem(item)"></div>
          </div>
          <div :class="['menu-m-view', item.checked ? 'isshowMenuItem' : '']">
            <div class="menu-m-item" v-for="(items, index) in item.childMenus" :key="index">
              <div class="menu-m-title">{{ items.menuName }}</div>
              <div class="menu-m-menuName" v-for="(cell, index) in items.childMenus" :key="index"
                @click="handleGopage(cell)">
                {{ cell.menuName }}
              </div>
            </div>
          </div>



        </div>
        <!-- <van-collapse v-model="activeM"  @change="handleChangeCollapse(activeM)">
          <van-collapse-item :title="item.menuName" :name="index" v-for="(item, index) in menuData" :key="index"
           >
            <div class="menu-m-item" v-for="(items, index) in item.childMenus" :key="index">
              <div class="menu-m-title">{{ items.menuName }}</div>
              <div class="menu-m-menuName" v-for="(cell, index) in items.childMenus" :key="index"
                @click="handleGopage(cell)">
                {{ cell.menuName }}
              </div>
            </div>
          </van-collapse-item>
        </van-collapse> -->
        <div class="brand" @click="handleGoBrand">BRAND STORY</div>
      </div>
    </van-popup>
  </div>
</template>

<script name="Header" setup>
import { onMounted, reactive, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import requestAsync from "../../utils/request";
import { useI18n } from 'vue-i18n'
import CountlyEvent from '../../utils/countlyEvent'
import Menu from "../common/Menu.vue"
import useScroll from "../../hooks/useScrollHeader"
import Cookies from 'js-cookie'
import { useStore } from "vuex";

const store = useStore();

const { locale } = useI18n()
const menuData = ref([1, 2, 3, 4, 5])
const activeMenu = ref({})
const active = ref({})
const isShowModel = ref(false)
const activeM = ref([1])
const isShowSkeleton = ref(false)
const isShowLoginFlag = ref(false)
const ShowMenuRouter = ref(['Order', 'Carts', 'FindPwd'])
const isShowMenuRouter = ref(true)

let { isFixed, InitScroll } = useScroll()
const route = useRoute();
const router = useRouter();
const goPage = (name) => {
  router.push(name);
};

const isLoginFlag = ref(false)


onMounted(() => {
  getMenuData();
  handleStorageEvent()
  isShowMenuRouter.value = !(ShowMenuRouter.value.includes(route.name))

});

const handleStorageEvent = () => {
  if (Cookies.get('uid')) {
    isLoginFlag.value = true
  } else {
    isLoginFlag.value = false
  }
}

window.addEventListener('scroll', InitScroll, true)


const handleShowFeedback = () => {
  const countly = new CountlyEvent()
  countly.handleShowFeedback()
}

const getMenuData = async () => {
  const data = await requestAsync({
    baseIp: window.location.origin,
    url: "/en_SG/js/roseonly_menu.json",
  });
  menuData.value = data.childMenus.childMenus;
  activeMenu.value = data.childMenus.childMenus[0];

  // setTimeout(() => {
  //   data.isShowSkeleton = false;
  // }, 300);
  // data.classData = changeMenuData(data.menuData);
  // console.log(data.menuData);
};

// const handleShowDropDown = (item) => {
//   if (!data.active) data.active = true;
//   data.activeMenu = item;
// };

// const handleHideDropDown = () => {
//   data.active = false;
// };

const handleGopage = (info) => {
  router.replace({
    name: "List",
    params: {
      id: info.id,
    },
  });
  active.value = false;
  isShowModel.value = false;
};

const handleGoHome = () => {
  router.replace({
    name: "Home",
  });
  active.value = false;
};

// const handleGopath = (name) => {
//   router.push({
//     name,
//   });
// };

// const handleChangeLang = (lang) => {
//   locale.value = lang
//   store.dispatch("changeLang", lang);
// }

// const handleChangeCollapse = (event) => {
//   console.log(111, event)
// }

const handleShowMenuItem = (item) => {
  item.checked = !item.checked
}

const handleGoBrand = () => {
  isShowModel.value = false;
  router.push({
    path: '/footer/brand',
  });
}

const handleGoPageAndName = (name) => {
  router.push({
    name,
  });
}

const handleShowLogin = () => {
  isShowLoginFlag.value = !isShowLoginFlag.value
}

const handleLoginOut = () => {
  Cookies.remove('uid')
  Cookies.remove('token')
  localStorage.removeItem('token')
  store.dispatch('login', false)
  isShowLoginFlag.value = false
  router.replace({
    name: 'Login'
  })
}




</script>

<style lang="scss" scoped>
/* pc */
@media screen and (min-width: 768px) {
  .container-pc {
    display: block !important;
    width: 100%;
    background-color: $globalColor;
    box-sizing: border-box;

    .container-pc-view {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 32px;
      color: #fff;
      background-color: $globalColor;

      .login-pc {
        width: 96px;
        background: url("https://www.roseonly.com.cn/images/logo_small.png") no-repeat center;
        background-size: 74px auto;
        text-align: center;
        height: 32px;
        cursor: pointer;
        font-size: 14px;
        background-color: #fff;
      }



      .container-pc-btns {
        display: flex;

        .btns-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 32px;
          font-size: 20px;
          color: $globalColor;
          font-weight: 700;
          margin: 0 8px;
        }

        .btns-text {
          position: relative;
          height: 32px;
          line-height: 32px;
          color: #fff;
          font-size: 15px;
          text-align: center;

          .show-login-view {
            display: block !important;
          }

          .login-view {
            display: none;

            position: absolute;
            top: 32px;
            right: -135px;
            width: 300px;
            // height: 150px;
            border: 1px solid #ccc;
            padding: 0 20px;
            background-color: #fff;

            .login-view-top {
              display: flex;
              width: 100%;
              height: 40px;
              justify-content: space-between;
              align-items: center;
              font-size: 12px;
              // border-bottom: 1px solid #ccc;
              padding: 10px 0;
              font-weight: 700;

              .login-view-he {
                width: 1px;
                height: 100%;
                background-color: #ccc;
              }

              div {
                width: 50%;
                color: #414141
              }
            }
          }
        }

        /* 图标 */

        /* 图标 */
      }
    }



    .icon-fangdajing {
      font-size: 22px !important;
    }

    .example-showcase {
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    .el-dropdown-link {
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 18px;
    }


  }

  .container-m {
    display: none !important;
    touch-action: auto !important;
  }
}

.login {
  display: flex;
  align-items: center;
  width: 100%;
  // height: 50px;
  justify-content: center;
  background-color: #fff;
  cursor: pointer;
  padding: 30px 0;

  img {
    width: 142px;
    height: auto;
    margin: 0 auto;
  }
}

/* 手机端 */
@media screen and (max-width: 767px) {
  .container-pc {
    display: none !important;
  }

  .container-m {
    display: block !important;
    width: 100%;
    height: 0.8rem;
    background-color: #fff;

    .container-m-view {
      // position: fixed;
      // top: 0;
      // left: 0;
      // z-index: 999;

      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      padding: 0 0.25rem;

      .container-m-btns {
        display: flex;

        .icon-fangdajing,
        .icon-gerenzhongxin,
        .icon-liebiao2 {
          font-size: 0.35rem;
          margin: 0 0.15rem;
        }

        .icon-login {
          font-size: 0.25rem;
          margin: 0 0.15rem;
        }


      }
    }

    .popup-view {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 0.2rem;
      height: 1rem;
    }

    .menu-m-view {
      height: 0;
      overflow: hidden;
    }

    .menu-m-item {
      color: #999;

      .menu-m-title {
        font-weight: 700;
        color: #000;
        margin: 0.2rem 0;
      }

      .menu-m-menuName {
        padding: 0.2rem 0;
        cursor: pointer;
      }
    }

    .logo-m {
      width: 1.5rem;
      background: url("https://www.roseonly.com.cn/images/logo_small.png") no-repeat center;
      background-size: 74px auto;
      text-align: center;
      height: 0.32rem;
    }

    .collapse-view {
      width: 100%;
      height: auto;
      font-size: .28rem;
      // background-color: pink;
      padding: 0 .3rem;

      .collapse-item {


        .collapse-item-title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: .8rem;
        }

        .isshowMenuItem {
          height: 100%;
          transition: height 0.8s ease-in-out;
        }
      }

      .jaintou {
        display: flex;
        align-items: center;
        justify-content: center;
        width: .5rem;
        height: .5rem;
      }
    }

    .brand {
      // font-size: .28rem;
      height: .8rem;
      line-height: .8rem;
    }
  }
}

/* ipad */
@media screen and (min-width: 768px) and (max-width: 1199px) {}

.container {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 0.8rem;
  background-color: #414141;
  z-index: 999;
  color: #fff;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 100%;
  font-size: 0.35rem;
}

/* .logo img {
  width: 2rem;
  height: 0.3rem;
} */
.header-right {
  position: absolute;
  right: 0.3rem;
  height: 0.4rem;
  color: #fff;
}


/* iconfont */
.icon-yonghu,
.icon-gouwudai {
  width: 32px;
  font-size: 18px !important;
  margin-right: 5px;
}

.icon-yonghu:hover,
.icon-gouwudai:hover {
  background: rgba($color: #000000, $alpha: .3);
}
</style>
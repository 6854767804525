export default {
    html: `
    <div class="right-cell">
    <div class="cell-view">
        <div class="cell-title">Q: What should I do if I forget my password?</div>
        <div class="cell-spant">A: Please click "Login" in the upper right corner of the official website. After entering the interface, click "Forgot Password" and follow the prompts; or retrieve the password through "Login" on the mobile phone. After entering the interface, enter "Register"</div>
    </div>

    <div class="cell-view">
        <div class="cell-title">Q: How to pay with WeChat?</div>
        <div class="cell-spant">A: Please use the computer to order. When paying, you can select "Scan QR code to pay". Scan QR code payment supports WeChat payment.</div>
    </div>


    <div class="cell-view">
        <div class="cell-title">Q: How far in advance are products generally reserved? If I need it urgently, can I place an additional order temporarily?</div>
        <div class="cell-spant">A: ROSEONLY gifts can be booked 3 months in advance at the earliest; if booked normally, orders placed before 14:00 every day can be shipped on the same day so that you can receive them as early as possible; if there is a ROSEONLY store in your city, you can place an order on the official website , choose "pick up at the store" or use "24-hour express delivery" for same-day delivery. If you urgently need to receive a gift, you can call ROSEONLY customer service 400-1314-520 (Monday to Sunday 9:00-21:00), and we will find a solution for you.</div>
    </div>



    <div class="cell-view">
        <div class="cell-title">Q: How to view the blessings?</div>
        <div class="cell-spant">A: When you place an order online and leave a "love message", the recipient can use mobile QQ, WeChat, mobile 360, Alipay and other scanning software to scan the QR code and enter the mobile phone number of the order recipient. After verification, the recipient can view the love message. language. At the same time, you can follow the official WeChat "ROSEONLY promise" and click "Exclusive Service" - "Love Language Query" to query love words at any time.</div>
    </div>


    <div class="cell-view">
        <div class="cell-title">Q: Can the delivery be delivered on time according to my requirements?</div>
        <div class="cell-spant">A: ROSEONLY has a partnership with SF Express and FedEx, and has dedicated personnel to follow up the delivery process at any time after delivery. Unless there are special circumstances, 99% of orders will be delivered before 18:00 on the customer's expected arrival date; occasionally, such as haze and rain Snow, flight delays and other uncontrollable factors affect logistics and distribution, please understand. If you are worried about a slight delay, the customer can choose to advance the expected arrival date.</div>
    </div>

    <div class="cell-view">
        <div class="cell-title">Q: What is the difference between fresh roses and everlasting roses?</div>
        <div class="cell-spant">A: The flower roses are freshly picked top-quality Ecuadorian roses. These roses grow in the rose fields closest to heaven, bathed in 365 days of sunshine and rain. We select roses in the garden with branches that can reach 1.5 meters tall, buds as high as 3 inches, and each flower as big as a heart. ROSEONLY only selects the best flower luxury products in the world. Eternal roses are Ecuadorian flower roses that have been carved for 60 days through 108 special processes, so that they can bloom for a long time and never wither. You can choose according to your preference.</div>
    </div>


    <div class="cell-view">
        <div class="cell-title">Q: How do I cancel an order and get a refund?</div>
        <div class="cell-spant">A: If the gift has not been shipped, you can log in to the "Personal Center" of the official website to apply for a refund yourself. The staff will refund within 48 hours. The time for the refund amount to arrive depends on each bank. If the order has been shipped, you cannot cancel the order, please understand.</div>
    </div>


    <div class="cell-view">
        <div class="cell-title">Q: Can I return or exchange the gift I received if I don’t like it?</div>
        <div class="cell-spant">A: Due to the particularity of ROSEONLY gifts, returns and exchanges due to personal preferences (smell, color, model, appearance, etc.) cannot be accepted if there are no quality problems. Please understand.</div>
    </div>

    <div class="cell-view">
        <div class="cell-title">Q: What should I do if I need an invoice?</div>
        <div class="cell-spant">
        A: Please send the order code, invoice header, invoice delivery address and contact information to ROSEONLY's official WeChat "ROSEONLY promise", click "Exclusive Service" - "Online Customer Service" to submit the above information, ROSEONLY will receive the information after receiving it Process it for you as soon as possible.</div>
    </div>

    <div class="cell-view">
        <div class="cell-title">Q: Why are the flowers I received different from the pictures online?</div>
        <div class="cell-spant">A: Flowers grow naturally, and the shape, size, and color of each flower are different. Secondly, due to differences in shooting light, angle, and computer browsers, there may be slight errors in the pictures.</div>
    </div>

    <div class="cell-view">
        <div class="cell-title">Q: Are the products purchased on Tmall genuine?</div>
        <div class="cell-spant">A: The official sales platforms of ROSEONLY include: ROSEONLY official website, Tmall ROSEONLY flagship store, and JD.com ROSEONLY flagship store. You can buy with confidence.</div>
    </div>
</div>
    `
}
<template>
    <div class="container-pc">
        <div :class="['tips-view']" v-if="location === 'CN' && isShowLocation">
            <div class="tips-title">根据您的位置推荐ROSEONLY的中国区网站, 请访问 <a href="https://www.roseonly.com.cn/"
                    @click="handleSetCookie">www.roseonly.com.cn</a></div>
            <div class="iconfont icon-guanbi1 CURSOR" @click="handleChangeIsShowLocation"></div>
        </div>
    </div>
</template>


<script name="LocationTips" setup>
import { ref, onMounted } from "vue";
import requestAsync from '../../utils/request'
import Cookies from 'js-cookie'


let location = ref('')
let isShowLocation = ref(false)
const language = ref('')

onMounted(async () => {
    language.value = navigator.language || navigator.userLanguage;
    await InitIP()
    await InitCookie()
})


const InitIP = async () => {
    const data = await requestAsync({
        baseIp: "https://api.roseonly.com/",
        url: 'ip'
    })
    location.value = data.data

    /* 是中文 并 浏览器语言不是中文 弹
        1. ip 是cn 并且浏览器语言不是cn 弹
        2. IP 是国外 不弹
        3. ip 是cn 并且浏览器语言是cn 直接跳官网
    */
    if (data.data == 'CN' && language.value != 'zh-CN') {
        isShowLocation.value = true
    }

    if (data.data == 'CN' && language.value == 'zh-CN') {
        window.location.href = 'https://www.roseonly.com.cn/'
    }
};

const InitCookie = () => {
    const locationIp = Cookies.get('locationIp')
    if (locationIp === 'CN') {
        isShowLocation.value = false
    }
}

const handleChangeIsShowLocation = () => {
    isShowLocation.value = false
}


const handleSetCookie = () => {
    Cookies.set('locationIp', 'CN')
}


</script>




<style lang="scss" scoped>
.tips-view {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    background-color: #fff;
    color: $globalColor;
    font-size: 12px;

    .tips-title {
        width: 100%;
        display: flex;
        justify-content: center;

        a {
            margin-left: 10px;
            border-bottom: 1px solid $globalColor;
        }
    }
}

.icon-guanbi1 {
    margin-right: 20px;
}
</style>
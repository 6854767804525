export default {
    html: `
    <div class="right-cell">
    <div class="cell-title">1. Register for shopping</div>
    <div class="cell-spant">1. Registration and login: Fill in the registration information according to the prompts. After completing, click "Register Now".</div>
    <div>
      <div class="cell-spant">2. Basic information:</div>
      <p class="cell-spant">(1) Log in to the "Personal Center" directly or complete the basic information according to the prompts before placing an order.</p>
      <p class="cell-spant">(2) Registered users need to fill in your loved one’s name.</p>
    </div>
    <div>
      <div class="cell-title">2. Purchase process</div>
      <div class="cell-spant">1. Place an order: Find your favorite gift through the categories on the navigation bar.</div>
    </div>
    <div>
      <div class="cell-spant">2. Add to cart:</div>
      <p class="cell-spant">(1) Click the "Add to Cart" button on the product details page and put the product into the shopping cart.</p>
      <p class="cell-spant">(2) In the shopping cart, the default is one quantity for each item. You can modify the quantity, delete items, and query gift information in "My Shopping Cart".</p>
    </div>
    <div class="cell-spant"> 3. Logistics information: Follow the prompts to fill in the delivery address, contact person, contact information and other information. After saving, click "Submit Order" to enter the settlement payment page.</div>
    <div class="cell-spant"> 4. Payment methods: including quick payment, online banking payment, and scan code payment. If payment is not made within 12 hours of submitting the order, the order will be automatically canceled. </div>
    <div class="cell-spant"> 5. Generate order: After successful payment, the system will automatically generate an order and display the order number.</div>
    <div class="cell-spant"> 6. Query orders: Log in and enter "Personal Center" → "My Orders" to query order information.</div>
    <div>
      <div class="cell-title">3. Issuance of invoices</div>
      <div class="cell-spant">1 Invoice details: According to the requirements of the tax management department, legal and valid invoices can be issued for goods sold on the ROSEONLY official website.</div>
      <div>
        <p class="cell-spant">(1) The invoice amount cannot be higher than the order amount.</p>
        <p class="cell-spant">(2) The invoice amount only includes the amount of the product itself, and does not include the amount of shipping costs, coupons, and vouchers.</p>
        <p class="cell-spant">(3) The invoice header content cannot be empty. You can choose an individual, company name or full name.</p>
        <p class="cell-spant">(4) Invoice content: details of purchased goods.</p>
        <p class="cell-spant">(5) Individuals and corporate customers without general taxpayer qualifications are issued ordinary invoices. If corporate customers need to issue special value-added tax invoices, they need to contact customer service personnel.</p>
        <p class="cell-spant">(6) The invoice will be issued after the order is signed and mailed to the consumer separately.</p>
      </div>
      <div class="cell-spant">3. Regulations for applying for a replacement invoice: Please apply for a replacement within one month after receiving the goods. Overdue invoices will not be accepted. Customer service staff will make a special trip to handle it for you, and the express delivery costs will be borne by ROSEONLY.</div>
      <div class="cell-spant">4. Application for reissue of invoice: Within one month after receiving the invoice, if you find that the invoice title, content or amount is wrong due to ROSEONLY reasons, please call ROSEONLY customer service hotline: 400-1314-520, and the customer service staff will handle it for you. ROSEONLY will issue a new invoice as soon as possible after receiving the returned invoice.
      </div>
    </div>
  </div>
    
    `
}
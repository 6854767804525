

import { createI18n } from 'vue-i18n'
import { zh_CN, en_US } from './locale/index'

const i18n = createI18n({
    fallbackLocale: 'en',//预设语言环境
    globalInjection:true,
    legacy: false,  // 没有该参数可能会报错
    locale: 'en',
    messages: {
        ch: zh_CN,
        en: en_US
    }
})

export default i18n

<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script name="APP" setup>
import Cookies from 'js-cookie'
import { useStore } from "vuex";
const store = useStore()



localStorage.setItem('siteCode', 'en_SG')


if (Cookies.get('uid')) {
  store.dispatch('login', true)
}

const InitIP = async () => {
  const locationIp = Cookies.get('locationIp')
  if (locationIp === 'CN') {
    window.location.href = 'https://www.roseonly.com.cn/'
  }
};

InitIP()




</script>

<style lang="scss"></style>

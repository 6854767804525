import { createStore } from "vuex";
import requestAsync from "../utils/request.js";

interface DAMNU_ENABLE {
    [en: string]: any
}

const store = createStore({
    state: {
        count: 0,
        appId: '4416',
        myCartData: [], // 购物车数据
        lang: 'en', // 语言 默认 en
        isLoginFlag: false
    },
    /* 
    
        同步处理state 数据 
        commit 触发 mutations 的方法
        mutations 不可处理异步的方法
    
    */
    mutations: {
        handleSubCount(state, data) {
            state.count += data
        },
        SET_MYCARTDATA(state, data) {
            state.myCartData = data
        },
        GET_MYCARTDATA(state) {

        },
        SET_LANG(state, data: string) {
            console.log(state, data)
            state.lang = data
            const langObj: DAMNU_ENABLE = {
                en: 'en_SG',
                ch: 'zh_CN'
            }
            const siteCode: string = langObj[data]
            console.log('siteCode', siteCode)

            localStorage.setItem('siteCode', siteCode)
        },
        SET_LOGIN(state, data: any) {
            state.isLoginFlag = data
        }
    },
    /* 
        专门处理异步操作
        在此处也要去使用commit 去触发 mutations。
        context.commit
        dispatch 去触发
    */
    actions: {
        changeLang(context, data) {
            // 浏览器语言切换
            context.commit('SET_LANG', data)
        },
        asyncHandleSet(context, data) {
            context.commit('handleSubCount', data)
        },
        async asyncGetMyCartData(context) {
            const MyCartData: any = await requestAsync({
                baseIp: "https://api.roseonly.com.cn",
                url: "/cart/seeMyCart",
                headers: {
                    token:
                        "eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyTmFtZSI6IjE3NTEzMDY2NjM4IiwidXNlcklkIjoyNjM0ODI1fQ.795PcWGCO45QGj9etqHYsbSF73PYOLVzfPRvgQdwkSA",
                },
            });
            console.log('MyCartData', MyCartData)
            const data = MyCartData.data
            context.commit('SET_MYCARTDATA', data)
        },
        addToShoppingCart(context, data) {
            const MyCartData: any = context.state.myCartData
            console.log('MyCartData', MyCartData)
            console.log('data', data)
            const cartInfo = {
                count: 1,
                proProducts: data
            }
            for (const j in MyCartData) {
                console.log('j', MyCartData[j])
                if (MyCartData[j].proProducts.id === cartInfo.proProducts.id) {
                    MyCartData[j].count += 1;
                    context.commit('SET_MYCARTDATA', MyCartData)
                    return
                }
            }
            MyCartData.push(cartInfo)
            context.commit('SET_MYCARTDATA', MyCartData)
        },
        login(context, data) {
            context.commit('SET_LOGIN', data)
        }
    }
})


export default store

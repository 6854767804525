
import { defineComponent } from "vue";
import { useRouter, useRoute } from "vue-router";
export default defineComponent({
  name: "Tabber",
  setup() {
    const route = useRoute()
    const router = useRouter()

    changePath();

    //  || localStorage.getItem("rosepath")
    // console.log(router);

    const data: Array<any> = [
      {
        id: 0,
        path: "/home",
        iconfont: "icon-shouye",
        name: "首页",
      },
      {
        id: 1,
        path: "/menu",
        iconfont: "icon-fenlei1",
        name: "分类",
      },
      {
        id: 2,
        path: "/carts",
        iconfont: "icon-gouwuche",

        name: "购物车",
      },
      {
        id: 3,
        path: "/my",
        iconfont: "icon-wode",
        name: "我的",
      },
    ];

    /* methods */
    function changePath(): string {
      const path = route.path;
      return path;
    }
    function toPage(path: string) {
      router.push({
        path,
      });
      changePath();
      //   localStorage.setItem("rosepath", path);
    }
    return { data, changePath, toPage };
  },
});


import { defineComponent } from "vue";
import { useRoute } from 'vue-router'
import Header from "../header/Header.vue";
export default defineComponent({
  name: "PagesView",
  components: { Header },
  setup() {
    const router = useRoute()
    console.log('router', router)
  }
});

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-abd57114"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(['menu-item', _ctx.changePath() == item.path ? 'isPath' : '']),
        key: index,
        onClick: ($event: any) => (_ctx.toPage(item.path))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(['iconfont', item.iconfont])
        }, null, 2),
        _createElementVNode("div", null, _toDisplayString(item.name), 1)
      ], 10, _hoisted_2))
    }), 128))
  ]))
}
// const countly = window.Countly
class CountlyEvent {
    constructor(name) {
        this.name = name
    }
    handleShowFeedback() {
        console.log(window.Countly)
        window.Countly.q.push([
            'enable_feedback',
            { 'widgets': ['623d88c8494f831a29790f22'] }
        ]);
        // countly.q.push(['enable_feedback', { 'widgets': ['623d88c8494f831a29790f22'] }]);
    }
    saveListId(data) {
        window.Countly.q.push(['add_event', {
            key: 'LIST',
            count: 1,
            segmentation: {
                'LIST_ID': data.id, // 列表ID
                'LIST_URL': data.url // 列表URL
            }
        }])
    }
    saveInfoId(data) {
        window.Countly.q.push(['add_event', {
            key: 'INFO',
            count: 1,
            segmentation: {
                'INFO_ID': data.id,
                'INFO_NAME': data.name,
                'INFO_URL': data.url
            }
        }])
    }
    saveGoAmazon(data) {
        window.Countly.q.push(['add_event', {
            key: 'Amazon',
            count: 1,
            segmentation: {
                'ID': data.id,
                'NAME': data.name,
                'COUNTRY': data.country,
                'COUNTRY_URL': data.countryUrl,
            }
        }])
    }
    saveFeedBack(data) {
        window.Countly.q.push(['recordRatingWidgetWithID', {
            key: data.id,
        }])
    }
    saveUserId(userid) {
        window.Countly.q.push(['change_id', userid])
    }

}

export default CountlyEvent

export default {
    html: `
    <div class="right-cell">
        <div class="cell-title">一 配送方式</div>
        <div>
            <div class="cell-spant">可选快递运输、专卖店自提、同城速递</div>
            <div class="cell-spant">1 快递运输：为保证礼物的安全与配送速度，ROSEONLY将选用顺丰快递或联邦快递配送，运费由ROSEONLY承担。</div>
            <div class="cell-spant">2 专卖店自提：下单时选择提货专卖店，并填写提货人手机号码。</div>
            <div class="cell-spant">3 同城速递（ROSEONLY专属速递服务）</div>
            <div class="cell-spant">送达范围：专卖店所在城市同城指定区域。送达时间：当日14:00之前下单当日送达，14:00之后次日送达。</div>
            <div class="cell-spant">速递费用：北京地区免费，其它城市配送费100元。</div>
        </div>
    </div>
    `
}
export default {
    html: `
    <div class="right-cell">
    <div class="cell-title">一 注册购物</div>
    <div class="cell-spant">1 注册登录：根据提示内容填写注册信息，填写完毕后，点击“立即注册”。</div>
    <div>
      <div class="cell-spant">2 基本信息:</div>
      <p>（1）直接登陆“个人中心”或在下单前根据提示完善基本信息。</p>
      <p>（2）注册用户需填写您的爱人名字。</p>
    </div>
    <div>
      <div class="cell-title">二 购买流程</div>
      <div class="cell-spant">1 下单：通过分类导航栏上的礼品分类，查找心仪礼物。</div>
    </div>
    <div>
      <div class="cell-spant">2 加入购物车：</div>
      <p>（1）在商品详细页面点击“加入购物车”按钮，将商品放入购物车中。</p>
      <p>（2）在购物车中，系统默认每件商品的订购数量为一件，您可以在“我的购物车”中修改数量、删除商品、查询礼品信息。</p>
    </div>
    <div class="cell-spant"> 3 物流信息：按提示填写正确详细的配送地址、联系人、联系方式等信息，保存后点击“提交订单”进入结算支付页面。 </div>
    <div class="cell-spant"> 4 支付方式：包含快捷支付，网银支付，扫码支付。提交订单12小时内未付款，本订单将自动取消。 </div>
    <div class="cell-spant"> 5 生成订单：成功支付后，系统将自动生成订单并显示订单编号。</div>
    <div class="cell-spant"> 6 查询订单：登陆进入“个人中心”→“我的订单”查询订单信息。</div>
    <div>
      <div class="cell-title">三 开具发票</div>
      <div class="cell-spant">1 发票细则：根据税务管理部门的要求，在ROSEONLY官网销售的商品均可开具合法有效的发票。</div>
      <div>
        <p>（1）发票金额不能高于订单金额。</p>
        <p>（2）发票金额只包含商品本身的金额，不含运费、优惠券、代金券金额。</p>
        <p>（3）发票抬头内容不能为空，您可选择个人、公司名称或姓名。</p>
        <p>（4）发票内容：购买商品的明细。</p>
        <p>（5）个人及不具有一般纳税人资格的企业客户，均开具普通发票。企业客户若需要开具增值税专用发票，需联系客服人员办理。</p>
        <p>（6）发票将在订单签收结束后开具，另行邮寄给消费者。</p>
      </div>
      <div class="cell-spant">3 申请补开的发票的规定: 请于商品签收一个月内申请补开，逾期不予受理。客服人员将专程为您办理，快递费用由ROSEONLY承担。</div>
      <div>4
        申请换开发票的情况：收到发票后一个月内，若发现因ROSEONLY原因所致票据抬头、内容或金额错误，请拨打ROSEONLY客服电话：400-1314-520，客服人员会为您处理，ROSEONLY将在收到退回的发票后尽快开具新发票。
      </div>
    </div>
  </div>
    `
}
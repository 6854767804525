
import config from '../apis/config'
import axios from 'axios'; // 引入axios
import Cookies from 'js-cookie'


const BASEURL = window.location.protocol + config.en_SG.prod.api.substring(6, 30)

// 环境的切换
if (process.env.NODE_ENV == 'development') {
    // axios.defaults.baseURL = config.en_SG.prod.api
    axios.defaults.baseURL = BASEURL
}
else if (process.env.NODE_ENV == 'debug') {
    axios.defaults.baseURL = 'https://api.roseonly.com';
}
else if (process.env.NODE_ENV == 'production') {
    // axios.defaults.baseURL = config.en_SG.prod.api
    axios.defaults.baseURL = BASEURL
}

axios.defaults.timeout = 10000;

// 添加请求拦截器
axios.interceptors.request.use((config) => {
    config.headers.common['token'] = localStorage.getItem('token')
    return config
})
axios.interceptors.response.use(
    response => {
        if (response.status === 200) {
            if (response.data.code == 500 && response.data.msg == '请登陆!' && response.data.status == '-5') {
                Cookies.remove('uid')
                Cookies.remove('token')
                localStorage.removeItem('token')
            }

            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    error => {
        if (error.response.status) {
            return Promise.reject(error.response);
        }
    }
)

function requestAsync(params) {
    const { baseIp, url, data, methods, headers } = params;
    let baseUrl = ''
    if (baseIp) {
        baseUrl = baseIp + url
    } else {
        baseUrl = url
    }
    let param = {}
    param = { ...data, siteCode: localStorage.getItem('siteCode') || 'en_SG' }
    // console.log(localStorage.getItem('siteCode'))
    if (methods == 'post') {
        return new Promise((resolve, reject) => {
            axios.post(baseUrl, param, headers).then(response => {
                resolve(response.data);
            });
        })
    } else {
        return new Promise((resolve, reject) => {
            axios.get(baseUrl, { params: param, headers }).then(response => {
                resolve(response.data);
            });
        });
    }

}



export default requestAsync
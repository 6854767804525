import { ref } from 'vue'
function useScroll() {

    let isFixed = ref(false)
    const InitScroll = () => {
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        if (scrollTop > 125) {
            isFixed.value = true
        } else {
            isFixed.value = false
        }
    }

    return {
        isFixed,
        InitScroll
    }
}


export default useScroll
export default {
    html: `
    <div class="right-cell">
        <div class="cell-view">
            <div class="cell-title">致所有支持ROSEONLY的伙伴：</div>
            <div class="cell-spant">为确保每一位顾客均能买到正品"ROSEONLY”，我司现声明如下：</div>
            <div class="cell-spant">1、“ROSEONLY”系列产品销售渠道仅限于：</div>
            <div class="cell-spant">线上销售渠道：</div>
            <div class="cell-spant">（1）品牌官网</div>
            <div class="cell-spant">官网名称：ROSEONLY.</div>
            <div class="cell-spant">官网网址：<a href="http://www.roseonly.com.cn/">http://www.roseonly.com.cn</a></div>
            <div class="cell-spant">（2）天猫 </div>
            <div class="cell-spant">ROSEONLY.官方旗舰店：https://roseonly.tmall.com</div>
            <div class="cell-spant">ROSEONLY.官方珠宝旗舰店：https://roseonlyzb.tmall.com</div>
            <div class="cell-spant">（3）京东</div>
            <div class="cell-spant">ROSEONLY.官方旗舰店：http://roseonly.jd.com</div>
            <div class="cell-spant">ROSEONLY.珠宝官方旗舰店：http://roseonlyzb.jd.com</div>
            <div class="cell-spant">ROSEONLY.自营旗舰店：http://mall.jd.com/index-1000014661.html</div>
            <div class="cell-spant">（4）官方微信</div>
            <div class="cell-spant">ROSEONLY.微信公众号名称：ROSEONLY诺誓</div>
            <div class="cell-spant">（5）官方微博</div>
            <div class="cell-spant">ROSEONLY.新浪微博：ROSEONLY诺誓、ROSEONLY为爱定制</div>
            <div class="cell-spant">（6）寺库</div>
            <div class="cell-spant">ROSEONLY.品牌名称：ROSEONLY.诺誓</div>
            <div class="cell-spant">（7）小红书APP</div>
            <div class="cell-spant">ROSEONLY.商家名称：ROSEONLY诺誓官方品牌店</div>
            <div class="cell-spant">线下销售渠道：</div>
            <div class="cell-spant">ROSEONLY.全国专卖店地址，请至官网查询：</div>
            <div class="cell-spant">ROSEONLY.全国专卖店地址，请至官网查询：<a href="http://www.roseonly.com.cn/about/shitidian.html">http://www.roseonly.com.cn/about/shitidian.html</a>
            </div>
            <div class="cell-spant">
                2、我司采取直营方式销售“ROSEONLY”系列产品。除本声明所列的销售渠道外，我司并未授权任何个人或公司通过其他任何渠道销售“ROSEONLY”任何产品，该渠道售出的产品均为假冒伪劣产品。
            </div>
            <div class="cell-spant">3、各位如发现销售假冒伪劣产品的任何信息，欢迎随时向我司举报，举报电话：【400-1314-520 或
                010-85888791】。同时，若您通过微商购买的产品，可以点击售假者微信，在“资料设置”中“投诉”。</div>

        </div>
    </div>
    `
}
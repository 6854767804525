export default {
    html: `
    <div class="right-cell">
    <div class="cell-title">1. Delivery service</div>
    <div class="cell-view">
        <div class="cell-spant">SF Express, self-pickup at specialty stores, and 24-hour express delivery are optional.</div>
        <div class="cell-spant">• SF FedEx: Shipping costs are borne by ROSEONLY (except for special gifts).</div>
        <div class="cell-spant">After the order is successfully paid, ROSEONLY will arrange delivery according to the expected delivery date you filled in.</div>
        <div class="cell-spant">For first- and second-tier cities, ROSEONLY will ship within 24 hours before your desired date.</div>
        <div class="cell-spant">For third-tier cities and other cities, roseonly will send it out within 48 hours before your desired date.</div>
    </div>

    <div class="cell-view">
        <div class="cell-spant">• Store pick-up: If there is a ROSEONLY store in your city, you can choose to place an order on the official website - store pick-up.</div>
        <div class="cell-spant">For details, please consult your local ROSEONLY store or ROSEONLY customer service.</div>
    </div>

    <div class="cell-view">
        <div class="cell-spant">• 24-hour express delivery: If there is a ROSEONLY store in your city, you can choose the "24-hour express delivery" service at your own expense.</div>
        <div class="cell-spant">Place an order and complete payment before 14:00 on the same day, and it can be delivered on the same day.</div>
        <div class="cell-spant">Place an order and complete payment between 14:00-24:00 on the same day, and it will be delivered the next day.</div>
    </div>

    <div class="cell-view">
        <div class="cell-spant">• 24-hour express delivery: If there is a ROSEONLY store in your city, you can choose the "24-hour express delivery" service at your own expense.</div>
        <div class="cell-spant">Place an order and complete payment before 14:00 on the same day, and it can be delivered on the same day.</div>
        <div class="cell-spant">Place an order and complete payment between 14:00-24:00 on the same day, and it will be delivered the next day.</div>
    </div>

    <div class="cell-view">
        <div class="cell-spant">*Special instructions for delivery service:</div>
        <div class="cell-spant">• Order confirmation: After you place an order successfully, ROSEONLY will check the order for you and send a confirmation message. You can log in to the ROSEONLY official website at any time, enter the order page, and check the logistics status.</div>
        <div class="cell-spant">• Order changes: If you want to change the delivery address or time, you can log in to the ROSEONLY official website, enter the "Personal Center", and find the order that needs to be modified in "My Orders". If the order shows "Undelivered" status, you can Click directly to modify the shipping information; if the order shows "shipped" status, the change cannot be accepted.
        </div>
        <div class="cell-spant">• Delivery delays: Based on past experience, 99% of orders will arrive on time, but occasionally there will be uncontrollable factors such as haze, rain, snow, flight delays, etc. Therefore, there is no 100% guarantee that they will arrive on your desired day. If this happens In this case, please bear with me and understand.</div>
    </div>
</div>
    `
}